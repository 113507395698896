import { Guid } from 'guid-typescript';
import { ModelBase } from 'src/app/models/base.model';

export class FirstAccessContact extends ModelBase {
    public id: Guid;
    public email: string;
    public phone: string;

    constructor(
        scope: string,
        captchaValue: string,
        id: Guid,
        email: string,
        phone: string) {
        super(scope, captchaValue);
        this.id = id;
        this.email = email;
        this.phone = phone;
    }
}
