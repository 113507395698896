import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as FW from '../../../../framework/core';
import { UserLogin } from '../models/user-login.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../../../../app.error-handler';
import { AuthService } from 'src/app/services/application/auth.service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root',
})
export class AuthenticateService {
    private _token: string;

    constructor(private http: HttpClient, private authService: AuthService) { }

    get token(): string {
        return this._token;
    }

    set token(newValue: string) {
        this._token = newValue;
    }

    public authenticateByCpf(scope: string, cpf: string, password: string, captchaValue: string): Observable<any> {
        this.authService.setUserDocument = cpf;
        return this.authenticate(new FW.Envelope(new UserLogin(captchaValue, password, scope, 1, '', cpf)));
    }

    public authenticateByCnpj(scope: string, cnpj: string, password: string, captchaValue: string): Observable<any> {
        this.authService.setUserDocument = cnpj;
        return this.authenticate(new FW.Envelope(new UserLogin(captchaValue, password, scope, 2, cnpj, '')));
    }

    private authenticate(envelope: FW.Envelope<UserLogin>): Observable<any> {
        return this.http.post<FW.Envelope<UserLogin>>(`${environment.baseApiUrl}/user/authenticate`, envelope, httpOptions)
        .pipe(catchError(ErrorHandler.handleError));
    }
}
