import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/framework/base-service';
import { IAdministrator } from 'src/app/models/IAdministrator';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { isNullOrUndefined } from 'util';
import { APIService } from '../application/api.service';
import { ContextService } from '../application/context.service';
import { Observable, of } from 'rxjs';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdministratorService extends BaseService {
  private baseUrl: string;
  public get serviceName(): string { return 'Administrators'; }

  constructor(
    private apiService: APIService,
    public context: ContextService) {
    super();
    this.baseUrl = '/adm';
  }

  search(filter: IAdministrator): Observable<Array<IAdministrator>> {
    let administrators: IAdministrator[] = [];
    const response = this.get(this.getQueryParams(filter))
      .pipe(map(response => {
        administrators = [];
        if (response != null) {
          for (const item of response.content) {
            const element: IAdministrator = {
              id: item.id,
              name: item.Name,
              cnpj: '',
            };
            administrators.push(element);
          }
        }

        return administrators;
      }));

    return response;
  }

  // async search(filter: IAdministrator): Promise<IAdministrator[]> {
  //     let administrators: IAdministrator[] = [];
  //     await this.get(this.getQueryParams(filter))
  //         .toPromise()
  //         .then(result => {
  //             administrators = [];
  //             if (result != null) {
  //                 for (const item of result.content) {
  //                     const element: IAdministrator = {
  //                         id: item.id,
  //                         name: item.Name,
  //                         cnpj: '',
  //                       };
  //                     administrators.push(element);
  //                 }
  //             }
  //         });

  //     return administrators;
  // }

  private getQueryParams(filter: IAdministrator): IQueryParams[] {
    let params: IQueryParams[];
    params = [
      { name: 'limit', value: 9999999, type: 'url' },
      { name: 'skip', value: 0, type: 'url' }, { name: 'desc', value: 1, type: 'query' },
      { name: 'orderby', value: 'createdOn', type: 'query' },
    ];

    if (!isNullOrUndefined(filter.name)) {
      params.push({
        name: 'name',
        value: filter.name,
        type: 'query'
      });
    }
    if (!isNullOrUndefined(filter.cnpj)) {
      params.push({
        name: 'cnpj',
        value: filter.cnpj,
        type: 'query'
      });
    }

    if (!isNullOrUndefined(filter.id)) {
      params.push({
        name: 'id',
        value: filter.id,
        type: 'query'
      });
    }

    return params;
  }

  private get(model: any): Observable<any> {
    const callURL = super.fabricateURL(`${this.baseUrl}/search`, model);
    const ctx: IAppContext = this.context.join(this.serviceName);
    if (callURL) {
      return this.apiService.get(ctx, callURL);
    } else {
      return of(null);
    }
  }
}
