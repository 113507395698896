import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/framework/base-service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { APIService } from 'src/app/services/application/api.service';
import { ContextService } from 'src/app/services/application/context.service';
import { IpRestrictionControl } from '../model/ip-restriction-control.model';
import * as FW from '../../../framework/core';

@Injectable({
  providedIn: 'root'
})
export class IpRestrictionControlService extends BaseService {
  private baseUrl: string = '/access-ip';
  public get serviceName(): string { return 'IpRestrictionControl'; }

  constructor(
      private apiService: APIService,
      public context: ContextService) {
      super();
  }

  search(params?: IQueryParams[]): Observable<any> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    let callURL = "";
    
    if(params.length)
      callURL = super.fabricateURL(`${this.baseUrl}/search`, params);
    else
      callURL = `${this.baseUrl}/search`;

    return this.apiService.get(ctx, callURL);       
  }

  public save(entity: IpRestrictionControl): Observable<IEnvelope<IpRestrictionControl>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const postEnvelope = new FW.Envelope(entity);
    return this.apiService.post(ctx, '/access-ip/save', postEnvelope);
  }

  public multipleSave(entity: IpRestrictionControl[]): Observable<IEnvelope<IpRestrictionControl[]>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const postEnvelope = new FW.Envelope(entity);
    return this.apiService.post(ctx, '/access-ip/multiple-save', postEnvelope);
  }

  public delete(entity: IpRestrictionControl): Observable<IEnvelope<IpRestrictionControl>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const postEnvelope = new FW.Envelope(entity);
    return this.apiService.put(ctx, '/access-ip/delete', postEnvelope);
  }
}
