import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { WarningLevel } from 'src/app/framework/enums';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from '../../../../../services/application/messageswal.service';
import { FirstAccessSecurity } from '../../../models/first-access/first-access-security.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-first-access-security',
  templateUrl: './first-access-security.component.html',
  styleUrls: ['./first-access-security.component.scss']
})
export class FirstAccessSecurityComponent implements OnInit {

  get userType(): AbstractControl { return this.navigationService.form.get('userType'); }
  get email(): AbstractControl { return this.navigationService.form.get('email'); }

  constructor(
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private context: ContextService) {
  }

  ngOnInit() {
  }

  async sendToken(): Promise<void> {
    if (this.navigationService.form.invalid) {
      return;
    }

    const security = new FirstAccessSecurity(
      this.context.authService.currentScope.key,
      '',
      +this.userType.value,
      this.navigationService.contacts.id,
      this.email.value
    );

    await this.firstAccessService.verifySecurity(security)
      .toPromise()
      .then(response => this.success(response))
      .catch(error => this.error(error));
  }

  private success(guid: any): void {
    this.navigationService.etapa += 1;
    this.navigationService.userId = guid;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: WarningLevel.Error, message: error }, () => { });
  }

}
