import { Component, OnInit } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { Router } from '@angular/router';
import * as FW from '../../framework/core';
import { Storage } from "../../framework/storage";

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss']
})
export class SectorsComponent implements OnInit {
  public userRole: Number;
  public sectors: any;
  constructor(private router: Router) {
    this.userRole = Number(Storage.getString("userrole"));
  }

  ngOnInit() {
    this.getSectorList();
  }

  private getSectorList() {
  }

  public activatedDivision(id: any) {
  }

  permissionAdmin() {
    if (this.userRole >= 127) {
      return true;
    }
    return false;
  }

  public changeActivatedDivision(id: any) {
  }

}
