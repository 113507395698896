import { IQueryParams } from '../models/IQueryParams';

export class BaseService {

    constructor() {
    }

    fabricateURL(baseURL: string, params: IQueryParams[]): string {
        let urlResult = baseURL;

        if (params && params.length > 0) {
            params.map((param: IQueryParams) => {
                switch (param.type) {
                    case "url":
                        urlResult += `/${param.value}`
                        break;
                    case "query":
                        urlResult = this.addQuestionMark(urlResult);
                        urlResult += `${param.name}=${param.value}&`
                        break;
                    default:
                        break;
                }
            })
            
            urlResult = this.sliceLastChar(urlResult);
        }

        return urlResult;
    }

    private addQuestionMark(url: string) {
        if (url.indexOf("?") === -1) return `${url}/?`;
        return url;
    }

    private sliceLastChar(url: string) {
        const questionMarkIndex = url.lastIndexOf("?");
        if (questionMarkIndex === (url.length - 1)) url = url.slice(0, -1);

        const slashIndex = url.lastIndexOf("/");
        if (slashIndex === (url.length - 1)) url = url.slice(0, -1);

        return url;
    }
}
