import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { WarningLevel } from 'src/app/framework/enums';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { FirstAccessToken } from '../../../models/first-access-token.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-first-access-token',
  templateUrl: './first-access-token.component.html',
  styleUrls: ['./first-access-token.component.scss']
})
export class FirstAccessTokenComponent implements OnInit {

  counter: { min: number, sec: number };

  private _attempt = false;

  constructor(
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private context: ContextService) { }

  get contact(): string {
    return this.navigationService.form.get('email').value;
  }

  get attempt(): boolean {
    return this._attempt;
  }

  get token(): AbstractControl { return this.navigationService.form.get('token'); }

  get time(): string {
    const minute = this.counter.min;
    const second = this.counter.sec;
    if (minute === 0 && second === 0) {
      return '00:00';
    } else if (minute <= 9 && second <= 9) {
      return `0${this.counter.min}:0${this.counter.sec}`;
    } else if (minute <= 9) {
      return `0${this.counter.min}:${this.counter.sec}`;
    } else if (second <= 9) {
      return `${this.counter.min}:0${this.counter.sec}`;
    }

    return `${this.counter.min}:${this.counter.sec}`;
  }

  ngOnInit() {
    this._attempt = false;
    this.startTimer();
  }

  async verifyToken(): Promise<void> {
    this._attempt = true;
    if (this.navigationService.form.invalid) {
      return;
    }

    const model = new FirstAccessToken(
      this.context.authService.currentScope.key,
      '',
      this.navigationService.userId,
      this.token.value
    );

    await this.firstAccessService.verifyToken(model)
      .toPromise()
      .then(response => this.success(response))
      .catch(error => this.error(error));
  }

  previous() {
    this.navigationService.clearValidationsForm();

    if (this.navigationService.recoveryPassword) {
      this.navigationService.etapa -= 2;
    } else {
      this.navigationService.etapa -= 1;
    }
  }

  startTimer() {
    this.counter = { min: 15, sec: 0 }
    const intervalId = setInterval(() => {
      if (this.counter.sec - 1 === -1) {
        this.counter.min -= 1;
        this.counter.sec = 59;
      } else {
        this.counter.sec -= 1;
      }
      if (this.counter.min === 0 && this.counter.sec === 0) {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  checkValidity(control: AbstractControl) {
    return this._attempt && control.valid;
  }

  checkInvalidity(control: AbstractControl) {
    return this._attempt && control.invalid;
  }

  private success(guid: any): void {
    this.navigationService.etapa += 1;
    this.navigationService.userId = guid;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: WarningLevel.Error, message: error }, () => { });
  }

}
