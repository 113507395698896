import { Guid } from 'guid-typescript';
import { isNullOrUndefined } from 'util';
import { ModelBase } from '../../../models/base.model';

export class NewPassword extends ModelBase {
    public id: Guid;
    public password: string;
    public passwordConfirm: string;

    constructor(
        scope: string,
        captchaValue: string,
        id: Guid,
        password: string,
        passwordConfirm: string) {
        super(scope, captchaValue);
        this.id = id;
        this.password = !isNullOrUndefined(password) ? btoa(password) : '';
        this.passwordConfirm = !isNullOrUndefined(passwordConfirm) ? btoa(passwordConfirm) : '';
    }
}
