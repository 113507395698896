import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ICheckStatus } from 'src/app/models/ICheckStatus';
import { TestService } from 'src/app/services/business/test.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit, AfterViewInit {

  constructor(private testService: TestService) { }
  
  public get frontEndVersion() : string {
    return environment.version;
  }
  
  private _status : ICheckStatus;
  public get statusVersion() : ICheckStatus {
    return this._status;
  }
  public set statusVersion(v : ICheckStatus) {
    this._status = v;
  }

  ngOnInit() {
    let body = document.getElementById('indexBody');
    if(body) {
      body.style.removeProperty('display');
    }
  } 

  ngAfterViewInit(): void {
    this.testService.checkStatus().subscribe((result: ICheckStatus) => {
      this._status = result;    
    });
  } 

}
