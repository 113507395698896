import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { PageActionsComponent } from './page-actions/page-actions.component';
import { SectorsComponent } from './sectors/sectors.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MessageComponent } from './message/message.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TreeNodeComponent } from './tree-node/tree-node.component';

export const appControls = [
  HeaderComponent,
  PageLoaderComponent,
  SidebarComponent,
  FooterComponent,
  PageActionsComponent,
  SectorsComponent,
  PaginationComponent,
  MessageComponent,
  BackButtonComponent,
  CalendarioComponent,
  TreeNodeComponent
];

@NgModule({
  declarations: [
    appControls,
    PageActionsComponent,
    SectorsComponent,
    PaginationComponent,
    MessageComponent,
    BackButtonComponent,
    CalendarioComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    appControls
  ]
})
export class AppControlsModule { }
