export const permissions = {
    ALL: 'Todas as permissões',
    MEETING: 'Assembleias',
    MEETING_INDIVIDUAL_REGISTRATION: 'Cadastro de assembleia individual',
    MEETING_INDIVIDUAL_REGISTRATION_CREATE: 'Cadastrar Assembleia',
    MEETING_INDIVIDUAL_REGISTRATION_CREATE_REGISTRATION_DRAFT: 'Salvar Rascunho',
    MEETING_DETAIL: 'Tela de Detalhe',
    MEETING_DETAIL_FOLLOW_UP: 'Acompanhamento',
    MEETING_DETAIL_CHANGE_DATES: 'Alterar datas',
    MEETING_DETAIL_RESEND_CONVOCATIONS: 'Reenviar convocações',
    MEETING_DETAIL_INDIVIDUAL_VOTING: 'Lançamento de votos individual',
    MEETING_DETAIL_FILE_MENU: 'Menu Arquivos',
    MEETING_DETAIL_FILE_MENU_DOWNLOAD_QUOTA_HOLDER_BASE: 'Download da base de cotistas',
    MEETING_DETAIL_FILE_MENU_DOWNLOAD_CONVOCATIONS_FILE: 'Download do arquivo de convocação',
    MEETING_DETAIL_FILE_MENU_DOWNLOAD_ATTACHMENT_FILE: 'Download do arquivo anexo de assembleia',
    MEETING_DETAIL_FILE_MENU_DOWNLOAD_VOTING_DOSSIER: 'Donwload do Dossiê de votações',
    MEETING_DETAIL_SUMMARY: 'Menu Resumo',
    MEETING_DETAIL_SUMMARY_ADD: 'Adicionar Resumo',
    MEETING_DETAIL_CANCELLATION_MENU: 'Menu Cancelamento',
    CANCEL_MEETING: 'Cancelar Assembleia',
    MEETING_BATCH_FILE: 'Assembleias em lote',
    MEETING_BATCH_FILE_LIST: 'Listar assembleias em lote',
    MEETING_BATCH_FILE_LIST_DETAILS: 'Detalhes da assembleia em lote',
    MEETING_BATCH_FILE_LIST_DETAILS_DOWNLOAD_CSV_FILES: 'Download de arquivos .CSV',
    MEETING_BATCH_FILE_CREATE: 'Cadastro de assembleia em lote',
    DRAFT: 'Rascunhos',
    DRAFT_LIST: 'Listar rascunhos',
    DRAFT_LIST_APPROVE: 'Aprovar rascunho',
    DRAFT_LIST_SEND_TO_COMMUNICATION_QUEUE: 'Enviar para a fila de comunicação',
    DRAFT_EDIT: 'Editar rascunho',
    DRAFT_EDIT_MEETING_DRAFT_SAVE: 'Salvar rascunho de assembleia',
    DRAFT_EDIT_SEND_MEETING_NOTICE: 'Enviar convocação de assembleia',
    ANNOUNCEMENT: 'Comunicados',
    ANNOUNCEMENT_NEW: 'Novo comunicado',
    ANNOUNCEMENT_NEW_REGISTER_NOTICE_BY_FUND: 'Cadastrar comunicado por fundo',
    ANNOUNCEMENT_DETAILS: 'Detalhes do comunicado',
    ANNOUNCEMENT_DETAILS_VIEW_TEXT: 'Visualizar o texto do comunicado',
    ANNOUNCEMENT_DETAILS_VIEW_STATUS_SUBMISSION: 'Visualizar o status de envio do comunicado',
    ANNOUNCEMENT_BATCH: 'Cadastro de comunicados em lote',
    ANNOUNCEMENT_BATCH_ADD: 'Cadastrar comunicado em lote',
    COMMUNICATIONS: 'Comunicações',
    VIEW_DOCUMENTS_COMMUNICATIONS: 'Visualizar documentos',
    SEND_EMAIL_COMMUNICATIONS: 'Reenviar por e-mail',
    DOCUMENTS: 'Documentos',
    LIST_DOCUMENTS: 'Dossiê de documentos',
    PROCURATIONS: 'Procurações',
    EDIT_PROCURATION: 'Aprovar ou reprovar procurações',
    MANAGERS: 'Gestores',
    CREATE_MANAGER: 'Cadastro de novo perfil de usuário gestor',
    EDIT_MANAGER: 'Editar usuário gestor',
    FUNDS: 'Fundos',
    CREATE_FUND: 'Cadastro de fundo individual',
    EDIT_FUND: 'Edição de fundos',
    CREATE_FUND_BATCH: 'Cadastro de fundos em lote',
    FUND_BATCH_FILE: 'Fundos em lote',
    FUND_BATCH_FILE_LIST: 'Listar fundos em lote',
    FUND_BATCH_FILE_LIST_DETAILS: 'Detalhes da fundo em lote',
    FUND_BATCH_FILE_LIST_DETAILS_DOWNLOAD_CSV_FILES: 'Download de arquivos .CSV',
    FUND_BATCH_FILE_CREATE: 'Cadastro de fundo em lote',
    RESPONSIBLE: 'Responsáveis',
    CREATE_RESPONSIBLE: 'Cadastrar novo responsável',
    EDIT_STATUS_RESPONSIBLE: 'Ativar ou inativar responsável',
    OMBUDSMAN: 'Ouvidoria',
    CREATE_OMBUDSMAN: 'Cadastar novo texto de ouvidoria',
    VIEW_TEXT_OMBUDSMAN: 'Visualizar textos',
    VOTATION: 'Votações',
    VIEW_DOCUMENTS_VOTES: 'Acesso aos documentos',
    PURGE_VOTES: 'Expurgar votos',
    USERS: 'Usuários',
    CREATE_USERS: 'Cadastrar novo usuário',
    EDIT_PROFILE_USER: 'Editar perfil de usuário',
    PROFILES: 'Perfis',
    FILE_TRAFFIC: 'Trafego de Arquivos',
    REPORTS: 'Relatórios',
    TEMPLATES: 'Templates'
} as const;
