import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/security/login/login.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { ViewLoader } from "./services/application/viewloader.service";
import { LockScreenComponent } from "./pages/security/lockscreen/lockscreen.component";
import { RouteGuardService } from "./services/application/routeguard.service";
import { FirstAccessComponent } from "./components/user/first-access/first-access.component";
import { FirstAccessIdentityComponent } from "./components/user/first-access/steps/first-access-identity/first-access-identity.component";
import { FirstAccessSecurityComponent } from "./components/user/first-access/steps/first-access-security/first-access-security.component";
import { FirstAccessTokenComponent } from "./components/user/first-access/steps/first-access-token/first-access-token.component";
import { FirstAccessPasswordComponent } from "./components/user/first-access/steps/first-access-password/first-access-password.component";
import { VersionComponent } from "./pages/version/version.component";
import { AdfsCallbackComponent } from "./pages/adfs-callback/adfs-callback.component";

const routes: Routes = [
  { path: "", redirectTo: "main", pathMatch: "full" },
  {
    path: "main",
    loadChildren: () =>
      import("src/app/pages/main/main.module").then(
        (module) => module.MainModule
      ),
    canActivate: [RouteGuardService],
    resolve: { loader: ViewLoader },
  },
  {
    path: "redirect/:scope",
    component: LoginComponent,
    resolve: { loader: ViewLoader },
  },
  { path: "login", component: LoginComponent, resolve: { loader: ViewLoader } },
  {
    path: "lock",
    component: LockScreenComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "access-help/:type",
    component: FirstAccessComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "first-access/identity",
    component: FirstAccessIdentityComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "first-access/security",
    component: FirstAccessSecurityComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "first-access/token",
    component: FirstAccessTokenComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "first-access/password",
    component: FirstAccessPasswordComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "about",
    component: VersionComponent,
    resolve: { loader: ViewLoader },
  },
  {
    path: "adfs/callback",
    component: AdfsCallbackComponent,
    resolve: { loader: ViewLoader },
  },
  { path: "**", component: NotFoundComponent, resolve: { loader: ViewLoader } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
