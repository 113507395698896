import { Component, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { WarningLevel } from 'src/app/framework/enums';
import { UserType } from 'src/app/models/user-type.enum';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { Contact } from '../../../models/contact.model';
import { FirstAccessIdentity } from '../../../models/first-access/first-access-identity.model';
import { RecoveryPasswordIdentity } from '../../../models/recovery-password/recovery-password-identity.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { NavigationService } from '../../../services/navigation.service';
import { RecoveryPasswordService } from '../../../services/recovery-password.service';

@Component({
  selector: 'app-first-access-identity',
  templateUrl: './first-access-identity.component.html',
  styleUrls: ['./first-access-identity.component.scss']
})
export class FirstAccessIdentityComponent implements OnInit {
  cnpjType = UserType.CNPJ;
  cpfType = UserType.CPF;

  private _tabSelected = UserType.CPF;
  private _attempt = false;

  constructor(
    private recoveryPasswordService: RecoveryPasswordService,
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private context: ContextService) {
    this.changeTypeLogin(UserType.CPF);
  }

  get tabSelected(): UserType {
    return this._tabSelected;
  }

  set tabSelected(newTab: UserType) {
    this._tabSelected = newTab;
  }

  get attempt(): boolean {
    return this._attempt;
  }

  get cpf(): AbstractControl { return this.navigationService.form.get('cpf'); }
  get cnpj(): AbstractControl { return this.navigationService.form.get('cnpj'); }
  get userType(): AbstractControl { return this.navigationService.form.get('userType'); }
  get fundacao(): AbstractControl { return this.navigationService.form.get('fundacao'); }
  get nascimento(): AbstractControl { return this.navigationService.form.get('nascimento'); }

  ngOnInit() {
    this._attempt = false;
  }

  async verify(): Promise<void> {
    this._attempt = true;
    if (this.navigationService.form.invalid) {
      return;
    }

    if (this.navigationService.recoveryPassword) {
      const model = new RecoveryPasswordIdentity(
        this.context.authService.currentScope.key,
        '',
        +this.userType.value,
        this.cpf.value,
        this.cnpj.value);

      this.recoveryPasswordService.verifyIdentity(model)
        .toPromise()
        .then(response => {
          this.navigationService.userId = response.id;
          this.navigationService.form.get('email').setValue(response.emails[0]);
          this.navigationService.etapa += 2;
        })
        .catch(error => this.error(error));
    } else {
      const identity = new FirstAccessIdentity(
        this.context.authService.currentScope.key,
        '',
        +this.userType.value,
        this.cpf.value,
        this.nascimento.value,
        this.cnpj.value,
        this.fundacao.value);

      await this.firstAccessService.verifyIdentity(identity)
        .toPromise()
        .then(response => this.success(response))
        .catch(error => this.error(error));
    }
  }

  changeTypeLogin(tab: UserType): void {
    this._attempt = false;
    this.navigationService.clearValidationsForm();
    this.setValidationsForm(tab);
    this.userType.setValue(tab);
  }

  checkValidity(control: AbstractControl) {
    return this._attempt && control.valid;
  }

  checkInvalidity(control: AbstractControl) {
    return this._attempt && control.invalid;
  }

  verifyCpfType(): boolean {
    return this._tabSelected === UserType.CPF;
  }

  verifyCnpjType(): boolean {
    return this._tabSelected === UserType.CNPJ;
  }

  private setValidationsForm(tabSelected: UserType): void {
    this.tabSelected = tabSelected;
    this.userType.setValidators([Validators.required]);
    if (tabSelected === UserType.CPF) {
      if (!this.navigationService.recoveryPassword) {
        this.nascimento.setValidators([Validators.required]);
      }
      this.cpf.setValidators([Validators.required]);
    } else {
      if (!this.navigationService.recoveryPassword) {
        this.fundacao.setValidators([Validators.required]);
      }
      this.cnpj.setValidators([Validators.required]);
    }
  }

  private success(contacts: Contact): void {
    this.navigationService.contacts = contacts;
    this.navigationService.etapa += 1;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: WarningLevel.Error, message: error }, () => { });
  }

}
