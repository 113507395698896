import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ScopeParametersService } from '../business/scope-parameters.service';

import { ContextService } from "./context.service";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService implements CanActivate {

  constructor(private context: ContextService, private scopeParametersService: ScopeParametersService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hasAuthData: boolean = this.context.authService.tryToRestoreSession();

    const redirectToUrl: string = state.url;
    let scope = null;
    let votation = null;
    let adfs = null;
    let id_token = null;

    if (this.context.navigation.hasQueryParameter("scope")) {
      scope = encodeURIComponent(this.context.navigation.getQueryParameter("scope"));
    }

    if (!scope) {
      scope = this.context.authService.scopeKey;
    }

    if (redirectToUrl.includes('votation') && this.context.navigation.hasQueryParameter("as")) {
      votation = this.context.navigation.getQueryParameter("as");
    }

    if (this.context.navigation.hasQueryParameter("adfs")) {
      adfs = encodeURIComponent(this.context.navigation.getQueryParameter("adfs"));
      id_token = encodeURIComponent(this.context.navigation.getQueryParameter("id_token"));
    }

    if (hasAuthData) {
      if (!this.context.authService.isSessionActive) {
        if (environment.isHomolog) {
          this.context.navigation.changeRoute("/login", { "scope": scope });
        } else {
          this.context.navigation.changeRoute("/login");
        }
        return false;
      }

    } else {
      if (votation) {
        if (environment.isHomolog) {
          this.context.navigation.changeRoute("/login", { "scope": scope, "vr": votation });
        } else {
          this.context.navigation.changeRoute("/login", { "vr": votation });
        }
        return false;
      }
      if (adfs) {
        this.context.navigation.changeRoute("/adfs/callback", { "token": id_token });
        return false;
      }
      if (environment.isHomolog) {
        this.context.navigation.changeRoute("/login", { redirectTo: redirectToUrl, "scope": scope });
      } else {
        this.context.navigation.changeRoute("/login", { redirectTo: redirectToUrl });
      }
      return false;
    }

    return true;
  }
}
