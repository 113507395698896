import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { Message, MessageBehavior } from "../../framework/message";
import { WarningLevel } from '../../framework/enums';
import { IAppContext } from '../../framework/interfaces';

import * as FW from "../../framework/core";

export type HttpErrorHandleDelegate = <T>(ctx: IAppContext, operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

@Injectable()
export class HttpErrorHandler {
    constructor() { }

    public createHttpErrorHandler(): HttpErrorHandleDelegate {
        return <T>(ctx: IAppContext, operation = 'operation', result = {} as T) => this.handleError(ctx, operation, result);
    }

    private handleError<T>(ctx: IAppContext, operation = 'operation', result = {} as T) {
        return (response: HttpErrorResponse): Observable<T> => {
            let errorDescription: string = null;
            let msgText: string = null;

            if (response.error) {
                if (response.error.message) {
                    errorDescription = `${response.error.message} (${response.message})`;
                    msgText = response.error.message.toString();
                } else {
                    errorDescription = `${response.error} (${response.message})`;
                    msgText = response.error.toString();
                }
            } else {
                errorDescription = response.message;
                msgText = errorDescription;
            }

            let detail = `At ${ctx.operationGroup} => ${operation} : (status : ${response.status || 'none'}) - "${errorDescription}"`;

            if (FW.isNull(response.status)) {
                ctx.messages.add(new Message(ctx.messageContainer, msgText, detail, WarningLevel.Error, MessageBehavior.Log));
            } else {
                switch (response.status) {
                    case 400:
                    case 409:
                        // INVALID FIELD OR RECORD ALREADY EXISTS

                        detail = "O servidor recebeu alguma informação inválida. " +
                            "Dica: Verifique o preenchimento dos campos da tela. Verifique se você escolheu alguma opção inválida. " +
                            "Pode haver campos cujo preenchimento é obrigatório; estes estão assinalados com asterisco (*). " +
                            "Informar uma data inválida ou um campo numérico inválido também pode gerar esse tipo de alerta. " +
                            "Por fim, existem campos que não podem exceder um número máximo de caracteres. Se for o caso, tente reduzir alguma informação muito extensa.";

                        ctx.messages.add(new Message(ctx.messageContainer, msgText, detail, WarningLevel.Warning, MessageBehavior.Fixed));
                        break;
                    case 401:
                        // UNAUTHORIZED OR SESSION EXPIRED
                        ctx.messages.add(new Message(ctx.messageContainer, msgText, detail, WarningLevel.Warning, MessageBehavior.Toast));
                        ctx.navigation.changeRoute("/login", { invalidToken: true });
                        break;
                    case 403:
                        // PERMISSION DENIED
                        ctx.messages.add(new Message(ctx.messageContainer, msgText, detail, WarningLevel.Warning, MessageBehavior.Toast));
                        break;
                    case 404:
                        // NO RECORDS FOUND

                        detail = "O servidor não retornou resultados para a sua pesquisa. Dica: Verifique os filtros informados e tente novamente. ";

                        ctx.messages.add(new Message(ctx.messageContainer, msgText, detail, WarningLevel.Info, MessageBehavior.Fixed));
                        break;
                    default:
                        // BUG
                        msgText = "Ocorreu um erro inesperado no servidor.";
                        ctx.messages.add(new Message(ctx.messageContainer, msgText, detail, WarningLevel.Error, MessageBehavior.Fixed));
                        break;
                }
            }
            return of(result);
        };
    }
}