import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirstAccessIdentity } from '../../user/models/first-access/first-access-identity.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FW from '../../../framework/core';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../../../app.error-handler';
import { FirstAccessSecurity } from '../models/first-access/first-access-security.model';
import { FirstAccessToken } from '../models/first-access-token.model';
import { NewPassword } from '../models/new-password.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};


@Injectable({
    providedIn: 'root',
})
export class UserPasswordService {

    constructor(private http: HttpClient) {
    }

    public saveNewPassword(model: NewPassword): Observable<any> {
        return this.http.post<FW.Envelope<NewPassword>>(
            `${environment.baseApiUrl}/user/new-password`,
            new FW.Envelope(model),
            httpOptions)
            .pipe(catchError(ErrorHandler.handleError));
    }
}
