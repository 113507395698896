import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { AuthService } from '../services/application/auth.service';

import * as FW from "../framework/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.authToken;
    let authReq: HttpRequest<any> = null;

    if (!FW.isNullOrBlank(authToken)) {
      authReq = req.clone({ setHeaders: { Authorization: "BEARER " + authToken } });
    } else {
      authReq = req.clone();
    }

    return next.handle(authReq).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          if (response.headers.has("x-auth-token") && response.headers.has("x-session-expires")) {
            this.authService.authToken = response.headers.get("x-auth-token");
            this.authService.expiresAt = (new Date(response.headers.get("x-session-expires")));
          }
        }
      }, error => {
        if (error && error.status == 401) {
          this.authService.logoff();
        }
      })
    );
  }
}
