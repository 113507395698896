import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/services/application/context.service';
import { AuthService } from 'src/app/services/application/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public userName: string;
  public userEmail: string;

  constructor(private router: Router, private context: ContextService, private authService: AuthService) { }

  ngOnInit() {
    this.userName = this.context.authService.userName;
    this.userEmail = this.context.authService.userEmail;
  }

  public navigateAlterPassword() {
    this.router.navigate(['/main/alterpassword']);
  }

  public doLogout() {
    this.authService.logoff();
    return false;
  }

  isAdfsAuthentication() {
    if (this.authService.currentScope &&
      this.authService.currentScope.adfs.enabled) {
      return true;
    }

    return false;
  }

}
