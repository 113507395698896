import { Injectable } from "@angular/core";
import { BaseService } from "src/app/framework/base-service";
import { APIService } from "../application/api.service";
import { ContextService } from "../application/context.service";
import { Observable, of } from "rxjs";
import { IAppContext, IEnvelope } from "src/app/framework/interfaces";
import * as FW from '../../framework/core';
import { IRole } from "src/app/models/IRole";
import { IQueryParams } from "src/app/models/IQueryParams";

@Injectable({
    providedIn: 'root'
})

export class RoleService extends BaseService {
    private baseUrl: string;

    public get serviceName(): string { return 'Role' };

    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = '/security/role'
    }

    public getById(id: string): Observable<IRole> {
        const params: IQueryParams[] = [
            { type: 'url', value: id, name: '' }
        ];
        
        const callURL = super.fabricateURL(`${this.baseUrl}`, params);
        
        const context: IAppContext = this.context.join(this.serviceName);
        
        return this.apiService.get(context, callURL);
    }

    public getListByFilter(params: IQueryParams[]): Observable<FW.Envelope<IRole[]>> {
        const callURL = super.fabricateURL(`${this.baseUrl}`, params);

        const context: IAppContext = this.context.join(this.serviceName);

        if (callURL) {
            return this.apiService.get(context, callURL);
        } else {
            return of(null);
        }
    }

    public save(role: IRole): Observable<IEnvelope<IRole>> {
        const context: IAppContext = this.context.join(this.serviceName);
        
        const postEnvelope = new FW.Envelope(role);

        return this.apiService.post(context, `${this.baseUrl}/save`, postEnvelope);
    }

    public update(role: IRole): Observable<IEnvelope<IRole>> {
        const context: IAppContext = this.context.join(this.serviceName);
        
        const postEnvelope = new FW.Envelope(role);

        return this.apiService.put(context, `${this.baseUrl}/update`, postEnvelope);
    }
}