import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/security/login/login.component';
import { AppControlsModule } from './controls/app-controls.module';
import { ViewLoader } from './services/application/viewloader.service';
import { httpInterceptorProviders } from './http-interceptors/interceptorprovider';
import { serviceProviders } from './services/serviceprovider';
import { HttpClientModule } from '@angular/common/http';
import { LockScreenComponent } from './pages/security/lockscreen/lockscreen.component';
import { FirstAccessComponent } from './components/user/first-access/first-access.component';
import { FirstAccessIdentityComponent } from './components/user/first-access/steps/first-access-identity/first-access-identity.component';
import { FirstAccessSecurityComponent } from './components/user/first-access/steps/first-access-security/first-access-security.component';
import { FirstAccessTokenComponent } from './components/user/first-access/steps/first-access-token/first-access-token.component';
import { FirstAccessPasswordComponent } from './components/user/first-access/steps/first-access-password/first-access-password.component';
import { NgxMaskModule } from 'ngx-mask';
import { MASK_CONFIG } from './app.mask';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { VersionComponent } from './pages/version/version.component';
import { ArchwizardModule } from 'angular-archwizard';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { BnNgIdleService } from 'bn-ng-idle';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AdfsCallbackComponent } from './pages/adfs-callback/adfs-callback.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    LockScreenComponent,
    FirstAccessComponent,
    FirstAccessIdentityComponent,
    FirstAccessSecurityComponent,
    FirstAccessTokenComponent,
    FirstAccessPasswordComponent,
    RedirectComponent,
    VersionComponent,
    AdfsCallbackComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppControlsModule,
    HttpClientModule,
    ChartsModule,
    NgxMaskModule.forRoot(MASK_CONFIG),
    ArchwizardModule,
    CodemirrorModule,
    OAuthModule.forRoot()
  ],
  providers: [
    httpInterceptorProviders,
    serviceProviders,
    ViewLoader,
    BnNgIdleService,
    {provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
