import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';

declare var $: any;

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit, AfterViewInit {
  @Input() label = "Data";
  @Input() placeholder = "Selecione uma data";

  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  private identification: string;

  public data: Date;

  public controls = {
    leftArrow: '<i class="fal fa-angle-left" style="font-size: 1.25rem"></i>',
    rightArrow: '<i class="fal fa-angle-right" style="font-size: 1.25rem"></i>'
  };

  constructor() {
    this.identification = Guid.create().toString();
  }

  get id(): string {
    return `data_${this.identification}`;
  }

  ngAfterViewInit(): void {
    $(`#${this.id}`).datepicker({
      orientation: "bottom left",
      format: 'dd/mm/yyyy',
      templates: this.controls
    });

    $(`#${this.id}`).on("change", function () {
      if (!!$(`#${this.id}`).val()) {
        const dateStart = ($(`#${this.id}`).val().split('/'));
        const formatDate = `${dateStart[2]}-${dateStart[1]}-${dateStart[0]}T00:00:00`;
        this.changed.emit(formatDate);
      }
    });
  }

  ngOnInit() {
  }
}
