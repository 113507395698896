import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { Observable, of } from 'rxjs';
import * as FW from "../../framework/core";
import { IScopeParameter } from 'src/app/models/IScopeParameter';

@Injectable({
    providedIn: 'root'
})
export class ScopeParametersService extends BaseService {
    private baseUrl: string;
    public get serviceName(): string { return "Meeting" };

    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = "/scopeParameters";
    }

    search(params?: IQueryParams[]): Observable<FW.Envelope<IScopeParameter[]>> {
        const callURL = super.fabricateURL(`${this.baseUrl}/search`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        if (callURL) {
          return this.apiService.get(ctx, callURL)
        } else {
          return of(null)
        }
    }
}
