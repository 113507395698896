import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { FirstAccessSteps } from '../models/first-access-steps.enum';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as FW from '../../../framework/core';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../../../app.error-handler';
import { Contact } from '../models/contact.model';
import { NewPassword } from '../models/new-password.model';
import { RecoveryPasswordIdentity } from '../../user/models/recovery-password/recovery-password-identity.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    form: FormGroup;

    private _etapa = FirstAccessSteps.PrimeiroAcesso;
    private _userContacts: Contact;
    private _recoveryPassword = false;
    private _userId: any;

    constructor(
        private fb: FormBuilder) {
        this.initializeForm();
    }

    get etapa(): number {
        return this._etapa;
    }

    set etapa(novaEtapa: number) {
        this._etapa = novaEtapa;
    }

    get tituloEtapa(): string {
        if (this._etapa === FirstAccessSteps.VerificacaoSeguranca) {
            return 'Verificação de segurança';
        } else if (this._etapa === FirstAccessSteps.VerificarIdentidade) {
            return 'Verificar identidade';
        } else if (this._etapa === FirstAccessSteps.CadastrarSenha) {
            return 'Cadastrar senha';
        }
        return this._recoveryPassword ? 'Recuperar Senha' : 'Primeiro Acesso';
    }

    get showStepIdentity(): boolean {
        return this._etapa === FirstAccessSteps.PrimeiroAcesso;
    }

    get showStepSecurity(): boolean {
        return this._etapa === FirstAccessSteps.VerificacaoSeguranca;
    }

    get showStepToken(): boolean {
        return this._etapa === FirstAccessSteps.VerificarIdentidade;
    }

    get showStepPassword(): boolean {
        return this._etapa === FirstAccessSteps.CadastrarSenha;
    }

    get recoveryPassword(): boolean {
        return this._recoveryPassword;
    }

    set recoveryPassword(newValue: boolean) {
        this._recoveryPassword = newValue;
    }

    get contacts(): Contact {
        return this._userContacts;
    }

    set contacts(newValue: Contact) {
        this._userContacts = newValue;
    }

    get userId(): any {
        return this._userId;
    }

    set userId(newValue: any) {
        this._userId = newValue;
    }

    public clearValidationsForm(): void {
        // tslint:disable-next-line: forin
        for (const key in this.form.controls) {
            this.form.get(key).clearValidators();
            this.form.get(key).updateValueAndValidity();
        }
    }

    private initializeForm(): void {
        this.form = this.fb.group({
            userType: [1, null],
            cpf: ['', null],
            cnpj: ['', null],
            fundacao: ['', null],
            nascimento: ['', null],
            email: [false, null],
            phone: [false, null],
            token: ['', null],
            password: ['', null],
            passwordConfirm: ['', null]
        });
    }

}
