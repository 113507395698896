import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {
  @Input() node: any;
  @Input() noPadding: boolean = false;
  @Output() parentStateChange = new EventEmitter<void>();
  isNodeOpen = true;

  ngOnInit(): void {
    this.updateCheckboxState();
    this.emitParentStateChange();
  }

  /**
   * Alterna o estado de expansão/colapso do nó
   * @param event Evento de clique
   */
  toggleNode(event: Event): void {
    this.isNodeOpen = !this.isNodeOpen;
    event.stopPropagation();
  }

  /**
   * Gerencia a mudança no checkbox do nó atual
   * @param event Evento de mudança no checkbox
   */
  onCheckboxChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;

    this.node.isChecked = isChecked;
    this.node.isIndeterminate = false;

    if (this.node.Tree) {
      this.propagateSelectionToChildren(this.node.Tree, isChecked);
    }

    this.emitParentStateChange();
  }

  /**
   * Atualiza o estado do nó com base nos estados de seus filhos
   * - Verifica se todos os filhos estão marcados (`isChecked`).
   * - Verifica se ao menos um filho está marcado ou em estado intermediário (`isIndeterminate`).
   */
  updateCheckboxState(): void {
    if (!this.node.Tree || this.node.Tree.length === 0) {
      return;
    }

    const allChildrenChecked = this.node.Tree.every((child: any) => child.isChecked);
    const someChildrenChecked = this.node.Tree.some((child: any) => child.isChecked || child.isIndeterminate);

    this.node.isChecked = allChildrenChecked;
    this.node.isIndeterminate = !allChildrenChecked && someChildrenChecked;
  }

  /**
   * Propaga a seleção/deseleção para todos os filhos em cascata
   * @param children Lista de filhos do nó atual
   * @param isChecked Estado de seleção (true ou false) a ser aplicado
   */
  propagateSelectionToChildren(children: any[], isChecked: boolean): void {
    children.forEach((child: any) => {
      child.isChecked = isChecked;
      child.isIndeterminate = false;

      if (child.Tree && child.Tree.length > 0) {
        this.propagateSelectionToChildren(child.Tree, isChecked);
      }
    });
  }

  /**
   * Notifica o pai sobre a mudança de estado do nó atual
   * - Atualiza o estado do nó antes de emitir o evento.
   * - Garante que os estados intermediários sejam propagados corretamente para os pais.
   */
  emitParentStateChange(): void {
    this.updateCheckboxState();

    this.parentStateChange.emit();
  }
}
