import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Location } from '@angular/common';

import { IAppRouteNavigationHandler } from "../../framework/interfaces";

import * as FW from "../../framework/core";

@Injectable({
    providedIn: 'root',
})
export class NavigationService implements IAppRouteNavigationHandler {
    constructor(private router: Router, public currentRoute: ActivatedRoute, private location: Location) {
    }

    public hasQueryParameter(key: string): boolean {
        let url: string = window.location.href;
        key = key.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
        if (!results) { return false; }
        if (!results[2]) { return false; }
        return true;
    }

    public getQueryParameter(key: string): string {
        return this.tryToGetQueryParameter(key);
    }

    private tryToGetQueryParameter(key: string, url?: string): string {
        if (FW.isNullOrBlank(url)) { url = window.location.href; }
        key = key.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)');
        let results = regex.exec(url);
        if (!results) { return null; }
        if (!results[2]) { return null; }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public redirect(url: string): void {
        if (FW.isNullOrBlank(url)) { return; }

        switch (url.substr(0, 1)) {
            case "/":
                this.changeRoute(url);
                break;
            case "#":
                this.changeRoute(url.substr(1));
                break;
            default:
                window.location.href = url;
                break;
        }
    }

    public goTo(url: string): void {
        window.location.href = url;
    }

    public changeRoute(url: string, parameters?: Params): void {
        this.router.navigate([url], {
            queryParams: parameters
        });
    }

    public changeQueryString(parameters: Params): void {
        this.router.navigate([], {
            relativeTo: this.currentRoute,
            queryParamsHandling: "merge",
            queryParams: parameters
        });
    }

    public goBack(): void {
        this.location.back();
    }

    public reloadPage(): void {        
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.currentRoute, queryParamsHandling: 'preserve' });
    }
}