import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { FirstAccessSteps } from '../models/first-access-steps.enum';
import { ActivatedRoute } from '@angular/router';
import { ClientAdditionInformationService } from 'src/app/services/business/client-additional-information';
import { ContextService } from 'src/app/services/application/context.service';

@Component({
  selector: 'app-first-access',
  templateUrl: './first-access.component.html',
  styleUrls: ['./first-access.component.scss']
})
export class FirstAccessComponent implements OnInit, AfterViewInit, OnDestroy {
  appVersion: string;

  constructor(
    private route: ActivatedRoute,
    private context: ContextService,
    public navigationService: NavigationService) {
  }

  get scopeLogo(): string {
    return this.context.authService.currentScope.brandImageUrl;
  }

  get scopeName(): string {
    return this.context.authService.currentScope.name;
  }

  ngOnInit() {
    this.navigationService.clearValidationsForm();
    this.navigationService.recoveryPassword = false;
    if (this.route.snapshot.params.type) {
      const stepType = this.route.snapshot.params.type;
      if (stepType === 'forget-password') {
        this.navigationService.recoveryPassword = true;
      }
    }
    this.navigationService.etapa = FirstAccessSteps.PrimeiroAcesso;
  }

  ngAfterViewInit(): void {
    // this.clientInformationService.loadScope();
  }

  ngOnDestroy(): void {
    this.navigationService.form.reset();
  }

}
