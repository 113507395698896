export class UserTwoFactor {
    public scope: string;
    public userAccount: string;
    public name: string;
    public email: string;
    public token: string;
    public authToken: string;

    constructor() {
    }

 
}